@use './style/variables' as v;
@use './style/reset';

body {
  color: v.$main-color;
  font-family: v.$font-family;
  font-weight: 400;
  height: 100vh;

  #root{
    display: flex;
    flex-direction: column;
    height: 100%;

    @media (min-width: 750px){
      height: 100vh;
    };
  }


  .container {
    max-width: v.$max-width;
    margin: 0px auto;
    padding: 0 20px;
  }

  .dark{
    background: v.$main-background-color;
  }

  .light{
    color: v.$secondary-color;
    background: v.$secondary-background-color;
    flex: 1 1 auto;
  }
}
