@use '../../style/variables' as v;

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  padding-top: 70px;
  margin-bottom: 31px;
  font-weight: 400;

  @media (max-width: 576px) {
    margin-bottom: 10px;
  }

  span {
    color: v.$active-color;
  }
}

.info {
  font-family: v.$font-family-secondary;
  letter-spacing: 1px;
  text-align: justify;
  padding-bottom: 70px;
}
