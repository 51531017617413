@use '../../style/variables' as v;

.info {
  margin-top: 54px;
  margin-bottom: 28px;
  color: v.$active-color;
}

.contacts {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  @media (max-width: 415px) {
    flex-direction: column;
    row-gap: 10px;
  }
}

.oldConnection {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.phone a,
.mail a {
  display: flex;
  align-items: center;
  column-gap: 20px;
  font-weight: 800;

  img {
    width: 20px;
    height: 20px;
  }
}

.newConnection {
  display: flex;
  column-gap: 20px;

  img {
    width: 45px;
  }
}
