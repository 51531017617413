* {
  padding: 0;
  border: 0;
  margin: 0;
}
// HTML5 display-role reset for older browsers
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

//! Добавление

// Указываем box sizing
*,
*::before,
*::after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

// даляем стандартную стилизацию для всех ul и il, у которых есть атрибут class
// Убираем внутренние отступы
ul[class],
ol[class] {
  padding: 0;
  list-style: none;
}

// Выставляем основные настройки по-умолчанию для body
html,
body {
  min-width: 320px;
  height: 100%;
}

body {
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

// Элементы a, сбрасываем до дефолтных стилей
a,
a:visited {
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:focus,
a:active {
  outline: none;
}

:focus,
:active {
  outline: none;
}

// Упрощаем работу с изображениями
img {
  vertical-align: top;
}

// Кнопки, инпуты, сброс стилей
input::-ms-clear {
  display: none;
}


button {
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

/* End of reset style */
