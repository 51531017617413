@font-face {
  font-family: 'Helvetica';
  src: local('Helvetica'), url('/assets/fonts/helvetica_regular.otf') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'Affect';
  src: local('Affect'), url('/assets/fonts/affect.otf') format('opentype');
  font-weight: 400;
}

$main-color: #fff;
$secondary-color: #333333;
$active-color: #ef9635;

$main-background-color: #2d1d29;
$secondary-background-color: #fff;

$icons-color: #7a1e6b;

$font-family: 'Affect', sans-serif;
$font-family-secondary: 'Helvetica', sans-serif;

$max-width: 628px;
