@use '../../style/variables' as v;

.container {
  min-height: 50px;
  overflow-x: hidden;
}

.upperElement {
  height: 15px;
  background: repeating-linear-gradient(-45deg, #ffcd00 0 9.5px, black 9.5px 13px);
}

.lowerElement {
  width: 2000vw;
  height: 35px;
  display: flex;
  align-items: center;
  background: black;
  color: v.$main-color;
  font-size: 1.5em;
  text-transform: uppercase;
  transform: translateX(-20px);

  @media (max-width: 635px) {
    transform: translateX(0px);
    padding-left: 20px;
  }

  @media (max-width: 1000px) {
    font-size: 1.2em;
  }

  @media (max-width: 576px) {
    font-size: 1em;
    height: 30px;
  }

  span {
    display: block;
    width: 350px;

    @media (max-width: 1000px) {
      width: 280px;
    }

    @media (max-width: 576px) {
      width: 241px;
      margin-right: 0;
    }
  }
}
